import React from "react";
import "./ContactMe.css";

function ContactMe() {
  return(
    <section id="contact">
      <div class="container">
        <div class="row">
        <div class="col-lg-12">
          <p class="section-heading"><i class="fas fa-address-card inline-dis"></i> Contact Me! </p>
        </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <p class="contact-description"> Thank you so much for taking out the time to browse my website! </p>
            <p class="contact-description"> I am currently open to any new grad software development opportunities. Feel free to connect with me on LinkedIn! </p>
          </div>
        </div>
        <div class="row">
        <div class="col-lg-2 hidden-md hidden-sm hidden-xs">
        </div>
        <div class="col-md-12 col-lg-12">
           <form action="mailto:aggrimarora@gmail.com" class="contact-form" method="post" enctype="text/plain">
              <div class="row">
                 <div class="col-lg-6">
                    <input type="text" name="name" placeholder="name" />
                 </div>
                 <div class="col-lg-6">
                    <input type="text" name="telephone" placeholder="telephone" />
                 </div>
                 <div class="col-lg-12">
                        <textarea placeholder="message" name="message" cols="30" rows="10"></textarea>
                        <input type="submit" value="send message" />
                 </div>
              </div>
           </form>
        </div>
    <div class="col-lg-2 hidden-sm hidden-md hidden-xs">
    </div>
    </div>
    </div>
    <div class="container-fluid footer">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-6 footer-col">
        <i class="fa fa-map-marker dis-block footer-icon"></i>
        <h5>Location</h5>
        <p>Toronto, Canada</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 footer-col">
        <i class="fa fa-phone footer-icon"></i>
        <h5>Phone</h5>
        <p>(+1) 437 214 3106</p>
      </div>
      <div class="col-lg-4 col-md-4 hidden-sm hidden-xs footer-col">
        <i class="fa fa-envelope footer-icon"></i>
        <h5>Email</h5>
        <p>aggrimarora@gmail.com</p>
      </div>
    </div>
    </div>
    </section>
  );
}

export default ContactMe;
