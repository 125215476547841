import React from 'react';
import "./Skills.css";
import JS from '../assets/icons/js.png';
import CPP from '../assets/icons/cpp.png';
import CSS from '../assets/icons/css.png';
import Java from '../assets/icons/java.png';
import Python from '../assets/icons/python.png';
import SQL from '../assets/icons/sql.png';
import TimelineDot from '@material-ui/lab/TimelineDot';
import IconMysql from 'react-devicon/mysql/original-wordmark';

function About() {
  return (
    <section id="skills">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <p class="section-heading"> <i class="fas fa-cogs"></i> Skills </p>
        </div>
      </div>
        <div class="row">
            <div class="col-md-4">
                <div class="card-box">
                    <div class="card-title">
                        <h2>Languages</h2>
                        <ul class="list">
                          <li> Java </li>
                          <li> Python </li>
                          <li> JavaScript </li>
                          <li> SQL </li>
                          <li> C++ </li>
                          <li> CSS </li>
                          <li> HTML </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card-box">
                    <div class="card-title">
                        <h2>Data</h2>
                        <ul class="list">
                          <li> MySQL </li>
                          <li> MongoDB </li>
                          <li> Apache Druid </li>
                          <li> Google Bigquery </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card-box">
                    <div class="card-title">
                        <h2>Tools</h2>
                        <ul class="list">
                          <li> Apache Kafka </li>
                          <li> Git </li>
                          <li> Django </li>
                          <li> ReactJS </li>
                          <li> NodeJS </li>
                          <li> ExpressJS </li>
                          <li> Apache Spark </li>
                          <li> Android Studio </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>
      </section>
  );
}

export default About;
