import React from 'react';
import './Projects.css';
import Keeper_gif from "../assets/images/Keeper.gif";
import Secrets_gif from "../assets/images/Secrets_gif.gif";
import Home_Page from "../assets/images/AYS_HOME.png";
import Profile_Page from "../assets/images/AYS_PROFILE.png";
import Search_Page from "../assets/images/AYS_SEARCH.png";
import AYS from "../assets/images/AYS.jpg";
import Bookstore from "../assets/images/DatabaseApp.gif";
import Sales_1 from "../assets/images/Sales_1.gif";
import Sales_2 from "../assets/images/Sales_2.gif";
import Map_1 from "../assets/images/Map_1.png";
import Map_2 from "../assets/images/Map_2.png";
import Map_3 from "../assets/images/Map_3.png";
import Map_4 from "../assets/images/Map_4.png";
import Map_5 from "../assets/images/Map_5.png";
import Twitter_1 from "../assets/images/trending.png";
import Twitter_2 from "../assets/images/sentiment.png";

function Projects() {
  return(
    <section id="projects">
    <div class="container">
    
          <div class="row">
          <p class="section-heading"> <i class="fas fa-code inline-dis"></i> Projects </p>
          </div>
          <div class="zoom">
            <div class="row">
              <div class="col-12">
              <h2 class="project-heading center">
                  <a href="https://github.com/aggrimarora/Neighbourhood-Explorer/tree/main" target="_blank"> Neighbourhood Explorer Tool <i class="icon fab fa-github"></i></a>
                  <hr class="underline" />
              </h2>
              </div>
            </div>
            <div class="row separate">
              <div class="col-lg-6 col-md-12">
                <div id="carousel1" class="carousel slide carousel-fade" data-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src={Map_1} class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={Map_2} class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={Map_3} class="d-block w-100" alt="..." />
                    </div>
                  </div>
                  <a class="carousel-control-prev" href="#carousel1" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carousel1" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <p class="project-description">
                    Let's say you want to rent a new place. What's the first factor you think about? Its location, RIGHT? Here's a Neighbourhood Explorer Tool that I developed for <strong><em>SoulRooms Inc.</em></strong> that gives you a nice idea about what your new nieghbourhood will look like!
                  </p>
                </div>
              </div>
            <div class="row separate">
                <div class="col-lg-6 col-md-12">
                  <p class="project-description">
                    I developed this tool from scratch using <strong>Javascript</strong> and <strong>HTML</strong>, <strong>CSS</strong> for the front end. I used the <strong>Google Maps API</strong> to get the data and also optimized the search results in a way that it showed the 20 closest results based on rating and categories.  
                    It can also be used to calculate routes to work/frequently visited places. Click <a href="http://goodroommates.ca/soulrooms/Map.html" target="_blank">here</a> to have a look at it! 
                  </p>
                </div>
                <div class="col-lg-6 col-md-12">
                <div id="carousel2" class="carousel slide carousel-fade" data-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src={Map_4} class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={Map_5} class="d-block w-100" alt="..." />
                    </div>
                  </div>
                  <a class="carousel-control-prev" href="#carousel2" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carousel2" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
                </div>
            </div>
            </div>
            <hr />
          <div class="zoom">
            <div class="row separate">
              <div class="col-12">
              <h2 class="project-heading center">
                  <a href="https://github.com/aggrimarora/Sales_Stats" target="_blank"> Lemonade Sales App <i class="icon fab fa-github"></i></a>
                  <hr class="underline" />
              </h2>
              </div>
            </div>
            <div class="row separate">
              <div class="col-lg-6 col-md-12 middle">
                <img class="project-image" src={Sales_1} alt=""/>
              </div>
              <div class="col-md-12 col-lg-6 middle">
                <p class="project-description"> This is a BackEnd Application that I developed using the <strong>Django Framework</strong> in <strong>Python</strong>. I used raw HTML form to create the order form and djagno model forms for the sales report. </p>
                <p class="project-description"> Customers can add items to cart and place orders. </p>
                <p class="project-description"> I have also used dynamic templating so any changes in the database will be reflected in the form automatically. </p>
              </div>
          </div>
          <div class="row separate">
            <div class="col-md-12 col-lg-6 middle">
              <p class="project-description"> Designed a database to keep a track of the staff, products and the orders received</p>
              <p class="project-description"> I created the database using the <strong>Sqlite3</strong> library in python. </p>
              <p class="project-description"> This app can also be used to generate a sales report that calculates the commission for the orders assisted by individual employees. </p>
            </div>
            <div class="col-lg-6 col-md-12 middle">
              <img class="project-image" src={Sales_2} alt=""/>
            </div>
          </div>
          </div>
          <hr />
          <div class="row zoom separate">
              <div class="col-md-12 col-lg-6 middle">
                  <h2 class="project-heading">
                      <a href="https://github.com/aggrimarora/Keeper" target="_blank">Keeper <i class="icon fab fa-github"></i></a>
                  </h2>
                  <hr class="underline" />
                  <p class="project-description"> I have always wanted to make a simple notes app because of how frequently I make a list of things to plan my day out! </p>
                  <p class="project-description"> I developed this using <strong>ReactJS</strong>, <strong>EJS</strong>, <strong>HTML</strong>, <strong>CSS</strong> and <strong>Bootstrap</strong>.</p>
              </div>
              <div class="col-lg-6 col-md-12 middle">
                  <img class="project-image" src={Keeper_gif} alt=""/>
              </div>
          </div>
          <hr />
          <div class="row zoom separate">
              <div class="col-lg-6 col-md-12 middle">
                  <img class="project-image" src={Secrets_gif} alt=""/>
              </div>
              <div class="col-md-12 col-lg-6 middle">
              <h2 class="project-heading">
                  <a href="https://github.com/aggrimarora/Secrets" taget="_blank">Secrets <i class="icon fab fa-github"></i></a>
              </h2>
              <hr class="underline" />
                <p class="project-description"> Secrets, a fun platform to share secrets anonymously! </p>
                <p class="project-description"> I developed this app using:
                  <ul>
                    <li> Front End: <strong>HTML, CSS, EJS and Bootstrap</strong> </li>
                    <li> For the back end, I used <strong>NodeJS</strong>. I also used <strong>MongoDB</strong> to store user data and google/facebook <strong>authentication</strong> to login a user.</li>
                  </ul>
                </p>
              </div>
          </div>
          <hr />
            {/* <div class="zoom"> */}
            <div class="row zoom separate">
            <div class="col-md-12 col-lg-6 middle">
              <h2 class="project-heading">
                      <a href="https://github.com/aggrimarora/TwitterRealtimeStreaming" target="_blank">Realtime Twitter Data Stream <i class="icon fab fa-github"></i></a>
                  </h2>
                  <hr class="underline" />
                  <p class="project-description"> As a part of the EECS 4413 Big Data course, I developed a Realtime Twitter Streaming Service that shows the trending tweets and sentiment analysis for tweets related to a particulat topic. </p>
                  <p class="project-description"> Tech Stack: <strong>Apache Spark (RDDs) </strong> to implement the concept of <strong>MapReduce</strong>, <strong>Python</strong> for visualization and streaming of data. </p>
              </div>
              <div class="col-lg-6 col-md-12">
                <div id="carousel1" class="carousel slide carousel-fade" data-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src={Twitter_1} class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={Twitter_2} class="d-block w-100" alt="..." />
                    </div>
                  </div>
                  <a class="carousel-control-prev" href="#carousel1" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carousel1" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
                
                </div>
                  

            </div>
            <hr />
            <div class="row zoom separate">
                <div class="col-lg-6 col-md-12 middle">
                    <img class="project-image" src={Bookstore} alt=""/>
                </div>
                <div class="col-md-12 col-lg-6 middle">
                <h2 class="project-heading">
                    <a href="https://github.com/aggrimarora/BookStore" target="_blank">Bookstore  <i class="icon fab fa-github"></i></a>
                </h2>
                <hr class="underline" />
                  <p class="project-description"> I developed this app when I discovered the <strong>Tkinter</strong> library in <strong>Python</strong>. </p>
                  <p class="project-description"> I wanted to learn how to build user interfaces using this library.
                  I also used <strong>Sqlite3</strong> to store the data.
                  </p>
                </div>
            </div>
            <hr />
            </div>
      </section>
  );
}

export default Projects;
