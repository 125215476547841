import React from 'react';
import './App.css';
import Home from "./components/Home";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Navbar from "./components/Navbar";
import ContactMe from "./components/ContactMe";
import Music from "./components/Music";
import Experience from "./components/Experience";

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <Experience />
      <Skills />
      <Projects />
      <Music />
      <ContactMe />
    </div>
   );
}

export default App;
