import React from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import KPMicon from '../assets/icons/kpm.png';
import INicon from '../assets/icons/in.png';
import SRicon from '../assets/icons/soulrooms.png';
import LassondeIcon from '../assets/icons/lassonde.png';


const useStyles = makeStyles((theme) => ({
    paper: {
      padding: '6px 16px',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
  }));

  const GrayTextTypography = withStyles({
    root: {
      color: "#494444",
      fontWeight: 600,
      fontFamily: "\"Helvetica Neue\",sans-serif"
    }
  })(Typography);

  const TypographyOrganization = withStyles({
    root: {
      color: "#6A6767",
      fontWeight: 550,
      fontFamily: "\"Helvetica Neue\",sans-serif",
      fontStyle: "italic"
    }
  })(Typography);

  const WhiteTextTypography = withStyles({
    root: {
      color: "white",
      fontWeight: 500,
      fontFamily: "\"Helvetica Neue\",sans-serif",
      fontStyle: "italic"
    }
  })(Typography);

function Experience() {
    const classes = useStyles();
  return (
    <section id="experience">
      <div className="container">
        <p class="section-heading"><i class="fas fa-desktop"></i> Experience </p>
        <Timeline align="alternate">
      <TimelineItem>
        <TimelineOppositeContent>
          <WhiteTextTypography variant="body2" color="textSecondary">
            Nov' 20 - Present
          </WhiteTextTypography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">
                <a href="https://www.linkedin.com/company/kpmpower/" target="_blank">
                    <img src={KPMicon} style={{borderRadius: "50%"}}/>
                </a>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <GrayTextTypography variant="h6" component="h1">
              Full Stack Developer
            </GrayTextTypography>
            <TypographyOrganization align="center">KPM Power</TypographyOrganization>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <WhiteTextTypography variant="body2" color="textSecondary">
            Sep 20 - Nov 20
          </WhiteTextTypography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">
          <a href="https://www.linkedin.com/company/imagenationmtl/" target="_blank">
          <img src={INicon} style={{borderRadius: "50%"}}/>
          </a>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <GrayTextTypography variant="h6" component="h1" color="light">
              Web Developer Intern
            </GrayTextTypography>
            <TypographyOrganization align="center">Image Nation</TypographyOrganization>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent>
          <WhiteTextTypography variant="body2" color="textSecondary">
            Jun 20 - Aug 20
          </WhiteTextTypography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">
              <a href="https://www.linkedin.com/company/soulrooms1/" target="_blank">
                <img src={SRicon} style={{borderRadius: "50%"}}/>
              </a>
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <GrayTextTypography variant="h6" component="h1">
            Software Developer Intern
            </GrayTextTypography>
            <TypographyOrganization align="center">SoulRooms</TypographyOrganization>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent>
          <WhiteTextTypography variant="body2" color="textSecondary">
            Sep 19 - Apr 20
          </WhiteTextTypography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">
            <a href="https://www.linkedin.com/company/excel-lassonde/about/" target="_blank">
                <img src={LassondeIcon} style={{borderRadius: "50%"}}/>
            </a>
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <GrayTextTypography variant="h6" component="h1">
              EECS Tutor
            </GrayTextTypography>
            <TypographyOrganization align="center">Volunteer: Excel Lassonde</TypographyOrganization>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
      </div>
    </section>
  );
}

export default Experience;
