import React from 'react';
import './Home.css';
import profile from "../profile_picture.png";


function Home() {
  return (
    <div class="container-fluid">
    <div class="container-fluid home-bg title-container">
      <div class="row title-row">
        <div class="col-lg-7 col-md-12 col-sm-12 title">

              <pre><a href="https://www.linkedin.com/in/aggrim-arora/" target="_blank"><i class="fab fa-linkedin fa-3x icon"></i></a>     <a href="https://github.com/aggrimarora" target="_blank"><i class="icon fab fa-github fa-3x"></i></a>     <a href="https://www.instagram.com/aggrimarora/" target="_blank"><i class="fab fa-instagram fa-3x icon"></i></a>
              </pre>
            <p id="changeText" class="big-heading"> Hello </p>
            <p class="small-heading"> Welcome to my website! </p>
            <br />

              <p class="about" style={{width: "inherit", borderLeft: "2px solid white", borderRight: "2px solid white"}}> A Friendly coder you would want in your workspace 
                <br />
                 4th year Computer Science undergrad at YorkU 
                <br />
                 Full Stack Developer @ KPM Power  </p>

            <p class="about">
              Feel free to checkout my projects!
            </p>
        <a href="#projects" ><button type="button" class="btn btn-outline-danger bt-portfolio">Portfolio</button> </a>
        </div>
          <div class="col-lg-5 col-md-12 col-sm-12 image-align">
            <img src={profile} class="profile-fit zoom" />
          </div>
        </div>

      </div>
    </div>
  );
}

export default Home;
