import React from "react";
import "./Music.css"
import Let_Me from "../assets/images/Let_Me_Love_You.jpg";
import Senorita from "../assets/images/2.jpg";
import Back_In_Black from "../assets/images/3.jpg";
import Unfaithful from "../assets/images/4.jpg";
import With_You from "../assets/images/5.jpg";
import I_Like from "../assets/images/6.jpg";
import Behkana from "../assets/images/Behkana.jpg";
import Somebody_else from "../assets/images/Somebody_Else.jpg";


function Hobbies() {
  return (
    <section id="music">
      <div class="container">
        <div class="row">
          <p class="section-heading"><i class="fas fa-music"></i> Music</p>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="music-description type"> Guitarist | Pianist | Vocalist </p>
            <p class="music-description"> I am a proactive member of the York University Musicians Society since I started my undergrad at York. I regularly perform at open MIC events and shows. I started playing the piano when I was in 6th grade and the guitar when I was in 8th grade and music has been an integral part of my life since then. I am a certified Rockchool (grade 3) guitarist.
            <br />
            Here are a few snippets of my performances.
            </p>
          </div>
        </div>
        <div class="row snippets">
              <div class="col-lg-3 col-md-6 col-sm-6 music-col zoom">
                <a href="https://drive.google.com/file/d/1WKsmViXPh9XTPm8rk1HGKGzIDCUnLi4Q/view?usp=sharing" target="_blank">
                  <img src={With_You} class="music-image small-screen" />
                </a>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 music-col zoom">
                <a href="https://drive.google.com/file/d/1WwVqAMccg9Qfd-U7kGbgdJKKu9ItxL00/view?usp=sharing" target="_blank">
                  <img src={Behkana} class="music-image" />
                </a>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 music-col zoom">
                <a href="https://drive.google.com/file/d/1SrEqigbdvoWEoBTFPiITDUl9OWjACJC2/view?usp=sharing" target="_blank">
                  <img src={Senorita} class="music-image" />
                </a>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 music-col zoom">
                <a href="https://drive.google.com/file/d/14wMyjPgZlfy8oe4B4q0otC9CKSHfE6Sb/view?usp=sharing" target="_blank">
                  <img src={Let_Me} class="music-image" />
                </a>
              </div>
        </div>
        <div class="row snippets">
              <div class="col-lg-3 col-md-6 col-sm-6 music-col zoom">
                <a href="https://drive.google.com/file/d/1WR3_nPJmT-0YADz0ITyXIQPdQTb3lOH1/view?usp=sharing" target="_blank">
                  <img src={Unfaithful} class="music-image" />
                </a>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 music-col zoom">
                <a href="https://drive.google.com/file/d/1WPsOVkti-k4mntoaLH_fy7jeXjtwGMFM/view?usp=sharing" target="_blank">
                  <img src={Somebody_else} class="music-image" />
                </a>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 music-col zoom">
                <a href="https://drive.google.com/open?id=1VPCYRa6jJpL7_OxxVzl1fH_w2WaxWYzL" target="_blank">
                  <img src={I_Like} class="music-image" />
                </a>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 music-col zoom">
                <a href="https://drive.google.com/file/d/1WbZW_GQ0wAPEpz_E0VGoikgQSAb_nJfg/view?usp=sharing" target="_blank">
                  <img src={Back_In_Black} class="music-image" />
                </a>
              </div>
        </div>
      </div>
    </section>
  );
}

export default Hobbies;
