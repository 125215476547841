import React from 'react';
import './Home.css';
import logo from "../logoA.png";

function Navbar () {
  return(
    <section id="home">
    <nav class="navbar fixed-top navbar-expand-lg navbar-expand-md navbar-dark">
     <div class="d-flex flex-nowrap w-100">
      <a class="navbar-brand logo-font" href="#">
       <img src={logo} alt="" class="logo-fit" />
        ggrim Arora
      </a>
    <button class="navbar-toggler ml-auto hidden-sm-up " type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
     </div>
    <div class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item active">
        <a class="nav-link nav-text" href="#home"><i class="fas fa-home inline-dis"></i> Home </a>
      </li>
      <li class="nav-item">
        <a class="nav-link nav-text" href="#experience"><i class="fas fa-desktop inline-dis"></i> Experience</a>
      </li>
      <li class="nav-item">
        <a class="nav-link nav-text" href="#skills"><i class="fas fa-cogs inline-dis"></i> Skills</a>
      </li>
      <li class="nav-item">
        <a class="nav-link nav-text" href="#projects"><i class="fas fa-code inline-dis"></i> Projects</a>
      </li>
      <li class="nav-item">
        <a class="nav-link nav-text" href="#music"><i class="fas fa-music inline-dis"></i> Music</a>
      </li>
      <li class="nav-item">
        <a class="nav-link nav-text" href="#contact"><i class="fas fa-address-card inline-dis"></i> Contact</a>
      </li>
    </ul>
    </div>
    </nav>
    </section>
  );
}

export default Navbar;
